import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
//import { StaticImage } from "gatsby-plugin-image"
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import { StaticImage } from "gatsby-plugin-image";
import ComponentTextblock1 from "../../components/component-textblock-1";
import ComponentMain8 from "../../components/main/component-main-8";
import GoogleMap from "google-map-react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageCompanyAccess = ({ data, location }) => {
  const intl = useIntl();
  const handleApiLoaded = ({ map, maps }) => {
    new maps.Marker({
      position: {
        lat: 51.46487,
        lng: 7.2139,
      },
      map,
    });
  };
  return (
    <Layout location={location} mode="white">
      <Seo
        title={
          intl.locale === "en"
            ? `How to visit Cyberdyne Care Robotics GmbH. Located in Bochum, Germany`
            : `Kommen Sie die Cyberdyne Care Robotics GmbH in Bochum besuchen`
        }
        description={
          intl.locale === "en"
            ? `Cyberdyne Care Robotics GmbH is Europe headquarter office, located in Germany. Our office is just behind BG Universitätsklinikum Bergmannsheil Bochum.`
            : `Cyberdyne Care Robotics GmbH ist der europäische Haupsitzt in Deutschland. Unser Zentrum liegt neben dem BG Universitätsklinikum Bergmannsheil Bochum.`
        }
        ogImage="/images/ogp/E185.jpg"
      />
      <section className="sect sect_PageMission_catch">
        <div className="container">
          <ComponentTextblock1
            title={
              intl.locale === "en"
                ? `We are looking forward to welcome you`
                : `Wir freuen uns auf Sie`
            }
            subtitle={intl.locale === "en" ? `Contact` : `Kontakt`}
            content={
              intl.locale === "en"
                ? `Please contact us via E-Mail or Phone or just come and visit our center. There is enough parking space in front of our building. You can also reach us by public transportation. Our Center is freely accessible.`
                : `Schreiben Sie uns, rufen Sie uns an oder kommen Sie vorbei. Parkplätze finden Sie bei uns vorm Haus und auch mit der Bahn kann man unser Zentrum gut erreichen. Der Zugang zu unseren Räumen ist selbstverständlich barrierefrei.`
            }
          />
          <div className="flex-row">
            {intl.locale === "en" ? (
              <div className="flex-column-7">
                <p className="message-label-bold txt txt-rot">
                  By local public transport
                </p>
                <p>
                  From the Bochum main train station, you can take the tram no.
                  318 direction to Bochum Dahlhausen, the tram no. 308 direction
                  to Hattingen Mitte or the low floor bus 394 direction to
                  Bochum Narzissenstr. Get off at the Bergmannsheil. From there
                  turn left into the Hunscheidstr. After 4 minutes you will
                  reach the ZNB on your right.
                </p>
                <p className="message-label-bold txt txt-rot">By car</p>
                <p>Limited parking spaces are available at the ZNB.</p>
              </div>
            ) : (
              <div className="flex-column-7">
                <p className="message-label-bold txt txt-rot">
                  Mit dem öffentlichen Nahverkehr
                </p>
                <p>
                  Vom Bochumer Hauptbahnhof können Sie mit der Straßenbahn Nr.
                  318 Richtung Bochum Dahlhausen fahren, mit der Straßenbahn Nr.
                  308 Richtung Hattingen Mitte oder mit dem Niederflurbus 394
                  Richtung Bochum Narzissenstraße.
                  <br />
                  Steigen Sie an der Haltestelle Bergmannsheil aus. Von dort
                  biegen Sie links in die Hunscheidstraße ab. Nach vier Minuten
                  erreichen Sie das ZNB auf der rechten Seite.
                </p>
                <p className="message-label-bold txt txt-rot">Mit dem Auto</p>
                <p>Parkplätze stehen bregrenzt vor dem ZNB zur Verfügung.</p>
              </div>
            )}
          </div>
        </div>
      </section>

      <section id="founder" className="sect sect_PageMission_catch sect-bg-bls">
        <div className="container">
          <div className="flex-row justify-content-between">
            <div className="flex-column-7">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `Information for visitors`
                  : `Informationen für unsere Besucher`}
              </h2>
              <p className="message-sm">
                {intl.locale === "en"
                  ? `Whether you are patients, family of patients, medical
                professionals, researcher, or medical distributors, if you are
                interested in getting to know more of Cybernics, HAL treatment,
                HAL system, and/or simply our company, please send us an
                inquiry.`
                  : `Ob Sie nun Patient, Familie eines Patienten, medizinische Fackraft, Wissenschaftler oder medizinischer Vertriebspartner sind und daran interessiert sind, mehr über Cybernics, HAL-Therapie und das HAL-System oder unser Unternehmen zu erfahren, schicken Sie uns bitte eine Nachricht.`}
              </p>
            </div>

            <div className="flex-column-4">
              {/* <StaticImage
              src="../../images/about/image-about-us-1.png"
              className="mb-4"
              alt=""
            />
            <p className="message-label-bold txt txt-rot">
              Prof. Dr. Yoshiyuki SANKAI
            </p>
            <p>President and CEO of Cyberdyne Inc.</p> */}
            </div>
          </div>
        </div>
      </section>

      <ComponentMain8 image="/images/products/motion-principal-big-teaser.png">
        <h2 className="h3 font-weight-semibold txt_white">
          {intl.locale === "en"
            ? `Cyberdyne Care Robotics GmbH`
            : `Cyberdyne Care Robotics GmbH`}
        </h2>
        <p className="message-sm txt_white mb-2">
          Hunscheidtstraße 34
          <br />
          44789 Bochum
        </p>
        <p className="message-sm txt_white mb-2">
          Tel.: 0234 587 300-0
          <br />
          Fax.: 0234 587 300-1
        </p>
        <p className="message-sm txt_white">
          E-Mail: bochum@ccr-deutschland.de
        </p>
        <div className="rectangle_bottom_right"></div>
      </ComponentMain8>
      <section className="contact-map-wrapper">
        <GoogleMap
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP_API_KEY }}
          // 初期表示時の中心地の座標
          defaultCenter={{ lat: 51.46487, lng: 7.2139 }}
          // 初期表示時のズームの度合いを設定(完成したMapは15を設定)
          defaultZoom={15}
          onGoogleApiLoaded={handleApiLoaded}
        />
      </section>

      <section className="sect sect-qa">
        <div className="container">
          <p className="h2 txt txt-rot mb-5">
            {intl.locale === "en"
              ? `Learn more about us and the Cybernics Technology`
              : `Erfahren Sie mehr über uns und die Cybernics Technologie`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `About us` : `Über uns`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Our revolutionary technology is a game-changer of what we have
  as medical experts and neuro-rehabilitation professionals.`
                    : `Unsere revolutionäre Technologie ist bahnbrechend für medizinische Experten und Fachkräfte in der neurologischen Rehabilitation.`}
                </p>
                <Link to="/company/about/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Cyberdyne Inc.</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The significant existence of technology is to support, assist,
  enhance, and regenerate functions of humans.`
                    : `Die entscheidende Aufgabe der existierende Technologie ist die menschliche Funktion zu unterstützen, verbessern und regenerieren.`}
                </p>
                <Link to="/company/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Founder` : `Gründer`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Creator of the Future. Our Society emerges to where physical
                space and cyber space are often overlapped. Cybernics technology
                helps us uniting Humans, Robotics, and Information system.`
                    : `Gestalter unser Zukunft. Unsere Gesellschaft entwickelt sich so, dass der physische und der cyber Raum immer mehr überlappen. Die Cybernics-Technologie hilft dabei Menschen, Roboter und Informationssysteme zu vereinen.`}
                </p>
                <Link to="/company#founder" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Vision and Mission`
                    : `Vision und Auftrag`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `We envision a future where humans and technology are
  seamlessly interfaced, feeding off of each other.`
                    : `Wir stellen uns eine Zukunft vor, in der Menschen und Technologie nahtlos miteinander verbunden sind und sich gegenseitig unterstützen.`}
                </p>
                <Link to="/company/mission/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Contact/Access` : `Anfahrt`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Cyberdyne Care Robotics GmbH based in Bochum, North
  Rhine-Westphalia. We welcome people from all over the world.`
                    : `Die Cyberdyne Care Robotics GmbH hat ihren Sitz in Bochum, Nordrhein-Westfalen. Wir begrüßen Menschen aus der ganzen Welt.`}
                </p>
                <div>
                  <Link to="/company/access/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};

export default PageCompanyAccess;
