import React from "react"

const ComponentMain8 = ({ image, children }) => {
  return (
    <section className="sect sect_Product__catch4 p-0">
      <div className="sect_Product__catch___2">
        <div className="">
          <div className="container__inner flex-row">
            <div className="flex-column-6">
              <img src={image} className="sect_Product__catch___img2" alt="" width="746" height="530" />
            </div>
            <div className="flex-column-6 pl-6 p-6">
              {children}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ComponentMain8
